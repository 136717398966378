import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gallery from '@browniebroke/gatsby-image-gallery';
import { graphql } from 'gatsby';
export const query = graphql`
  query burullusIsland {
    images: allFile(
      filter: { relativeDirectory: { eq: "burullus-island" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(layout: CONSTRAINED, width: 500, height: 300)
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
          name
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Burullus Island",
  "date": "2021-06-23T00:00:00.000Z"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery images={props.data.images.edges.map(({
      node
    }) => ({
      ...node.childImageSharp,
      caption: node.name,
      thumbAlt: node.name
    }))} mdxType="Gallery" />
    <p>{`The name of Burullus is mentioned in the history books in conjunction with the word thug, and this indicates the military importance of Burullus in terms of being the subject of a bond. Imam al-Sakhawi said about her:
Yaqubi said:
That is, the position of the army and weapons to monitor any attempt of aggression against Islamic lands.
The Moroccan traveler Muhammed bin Abdullah bin Muhammad al-Tanji, known as Ibn Battuta, passed by al-Burullus.`}</p>
    <p>{`Story from Burullus Island, a tiny island in Kafr El-Sheikh, Egypt`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      